.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1em;

  label {
    padding-right: 0.8em;
    font-size: 1.1em;
  }
}

.result {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;

}
