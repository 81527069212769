.container {
  padding: 2em;
  display: flex;
  gap: 2em;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  > * {
    width: 11em;
    height: 6em;
  }
} 
