@use './main';

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
#root,
body {
  height: 100%;
  width: 100%;
}

a {
  color: #505050;
  text-decoration: none;
}

input {
  width: 5em;
  font-size: 1.2em;
  padding: 0.5em 0.2em 0.5em 0.2em;
  text-align: center;
  @include main.border;
}

textarea {
  font-size: 1.2em;
  resize: none;
  @include main.border;
}

select {
  width: 5em;
  font-size: 1.2em;
  padding: 0.5em 0.2em 0.5em 0.2em;
  text-align: center;
  @include main.border;
}

h1 {
  font-size: 3em;
  text-transform: uppercase;
}

h2 {
  font-size: 2em;
  text-transform: capitalize;
}
