@mixin border {
  border: 1px solid #ebebeb;
  border-radius: 5px;
  background-color: #f8f8f8;
}

@mixin error {
  border: 1px solid #d32f2f;
  border-radius: 15px;
  background-color: #ef5350;
  color: white;
  padding: 0.5em 1.5em;
}

@mixin button {
  background-image: linear-gradient(-180deg, #6dd5fa 0%, #2980b9 100%);
  box-shadow: 2px 2px 5px gray;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-image: linear-gradient(-180deg, #509dbd 0%, #2980b9 100%);
  }

  &:disabled {
    filter: grayscale(1);
  }
}
