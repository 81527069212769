@use '../../../main';

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1em;
  padding: 0 1em;
}

.header {
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.backLink {
  align-self: flex-start;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 16px;
  border-radius: 10%;

  &:hover {
    background-color: #ddd;
    color: black;
  }
}

.row {
  @media screen and (max-width: 800px) {
    width: 100%;
  }

  width: 60%;
  align-self: center;
}

.result {
  margin-top: 0.5em;
  padding: 1em 0.3em;
  text-align: center;
  word-wrap: break-word;
  font-size: clamp(1em, 5vw, 2em);
  @include main.border;
}
